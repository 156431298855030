import React, { useState } from "react";
import { MdAdd, MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getNumberofPax } from "../redux/actions/paxnumber";

const options = [
  { value: "AA", label: "American Airlines" },
  { value: "P4", label: "Airpeace" },
  { value: "C5", label: "Champlain Air" },
  { value: "BA", label: "British Airways" },
  { value: "CP", label: "Compass Airlines" },
  { value: "DL", label: "Delta Air Lines" },
  { value: "B6", label: "JetBlue Airways" },
  { value: "CO", label: "Continental Airlines" },
  { value: "DH", label: "Atlantic Coast Airlines" },
  { value: "NW", label: "Northwest Airlines" },
  { value: "OS", label: "Austrian Airlines" },
];

const PassengerInfo = ({
  passenger,
  onPassengerChange,
  onRemovePassenger,
  showAddButton,
  onAddPassenger,
}) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const { passengersinfo } = useSelector((state) => state.passengers);

  const handleDeleteButton = () => {
    dispatch(getNumberofPax(passengersinfo.length - 1));
    onRemovePassenger();
  };

  // Handle change in select dropdown
  const handleSelectChange = (props) => {
    onPassengerChange("airline", props.label);
    setSelectedOption(props.label);
  };

  const handleAddButton = (e) => {
    e.preventDefault();

    // Validation: Ensure all fields are filled
    if (
      !passenger.lastName.trim() ||
      !passenger.firstName.trim() ||
      !passenger.email.trim() ||
      !passenger.confirmation.trim() ||
      !selectedOption
    ) {
      alert("Please fill in all fields before adding a new passenger.");
      return;
    }

    dispatch(getNumberofPax(passengersinfo.length + 1));
    onAddPassenger();
  };

  return (
    <div className="flex w-full flex-col gap-1 items-start border-b-2 border-grey-500 border-dashed pb-5">
      <p className="text-[12px] font-[500]">PASSENGER</p>

      <form className="w-full items-center flex justify-between gap-2 flex-wrap">
        <input
          type="text"
          placeholder="Last Name"
          value={passenger.lastName}
          onChange={(e) => onPassengerChange("lastName", e.target.value)}
          className="p-2 outline-none border border-gray-500 text-[12px] grow rounded min-w-[180px]"
          required
        />

        <input
          type="text"
          placeholder="First Name"
          value={passenger.firstName}
          onChange={(e) => onPassengerChange("firstName", e.target.value)}
          className="p-2 outline-none border border-gray-400 text-[12px] grow rounded min-w-[180px]"
          required
        />

        <input
          type="email"
          placeholder="Email"
          value={passenger.email}
          onChange={(e) => onPassengerChange("email", e.target.value)}
          className="p-2 outline-none border border-gray-400 text-[12px] grow rounded min-w-[180px]"
          required
        />

        <Select
          defaultValue={selectedOption}
          onChange={handleSelectChange}
          options={options}
          className="p-2 outline-none text-[12px] rounded grow min-w-[180px]"
          placeholder="Originating Airline"
          required
        />

        <input
          type="text"
          placeholder="Airline Confirmation #"
          value={passenger.confirmation}
          onChange={(e) =>
            onPassengerChange("confirmation", e.target.value.toUpperCase())
          }
          className="p-2 uppercase outline-none border border-gray-400 text-[12px] grow rounded min-w-[180px]"
          required
        />

        <button
          className="p-2 rounded bg-[red] border border-transparent cursor-pointer"
          onClick={handleDeleteButton}
        >
          <MdDelete color="white" />
        </button>

        {showAddButton && (
          <button
            className="p-2 rounded bg-[green] border border-transparent cursor-pointer"
            onClick={handleAddButton}
          >
            <MdAdd color="white" />
          </button>
        )}
      </form>
    </div>
  );
};

export default PassengerInfo;
