import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNumberofPax } from "../redux/actions/paxnumber";

const PassengerCount = () => {
  const dispatch = useDispatch();
  const { paxvalue } = useSelector((state) => state.passengers);

  const handlePaxChange = (e) => {
    const count = e.target.value;

    if (parseInt(count) >= 1) {
      dispatch(getNumberofPax(count));
    }
  };

  return (
    <div className="flex flex-col gap-4 items-center">
      <div className="justify-center items-center w-full bg-[#67809F] p-1">
        <p className="text-[white] font-[600] text-center text-[13px]">
          STEP 2: SELECT NUMBER OF PASSENGERS
        </p>
      </div>

      <input
        type="text"
        min="1"
        value={paxvalue}
        onChange={handlePaxChange}
        className="w-[80px] outline-none p-1 text-[12px] border border-gray-400"
      />
    </div>
  );
};

export default PassengerCount;
