import React, { useState, useEffect } from "react";

import PassengerCount from "../component/PassengerCount";
import Checkout from "../component/Checkout";
import ServiceSelection from "../component/ServiceSelection";
import TripInformation from "../component/TripInformation";
import { useSelector } from "react-redux";
import Topbar from "../component/Topbar";
import Loader from "../component/Loader/Loading";

const BookingPage = () => {
  const { isLoading } = useSelector((state) => state.passengers);

  return (
    <div className="w-full flex flex-col gap-2 relative">
      {isLoading && <Loader type="spin" text="Uploading Data..." />}
      <Topbar tab={1} />

      <div className="w-[90%] h-full m-auto max-w-[1200px] flex flex-col gap-5 px-[30px] py-3">
        <ServiceSelection />

        <PassengerCount />

        <TripInformation />

        <Checkout />
      </div>
    </div>
  );
};

export default BookingPage;
