import React from "react";
import ReactLoading from "react-loading";
import "./Loading.css";

const Loader = ({ type, color, text }) => (
  <div className="w-full h-full fixed top-0 left-0 bg-[#808080af] flex justify-center items-center z-[2]">
    <div className="loadingBox gap-2">
      <ReactLoading
        type={type}
        color={"white"}
        height={"100%"}
        width={"100%"}
      />
      <span id="Loadingtag" className="text-[white] font-bold">
        {text}
      </span>
    </div>
  </div>
);

export default Loader;
