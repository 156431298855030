import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookingPage from "./page/landingpage";
import Reviewpaypage from "./page/reviewpaypage";

function App() {
  return (
    <Router>
      <div className="AppContainer">
        <Routes>
          <Route exact path="/" element={<BookingPage />} />
          <Route
            exact
            path="/checkoutpage/:servicekey"
            element={<Reviewpaypage />}
          />
        </Routes>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Router>
  );
}

export default App;
