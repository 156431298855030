import React, { useState } from "react";
import ribbonbagsgold from "../assets/images/ribbonbg.svg";
import Store from "../redux/store";
import { getServiceLevel } from "../redux/actions/paxnumber";
import { useSelector } from "react-redux";

const ServiceSelection = () => {
  const { servicelevel } = useSelector((state) => state.passengers);
  const [selectedService, setSelectedService] = useState(servicelevel.name);

  const services = [
    { name: "Gold", price: 10.0, coverage: 1000, value: "Gold Service" },
    {
      name: "Platinum",
      price: 15.0,
      coverage: 1500,
      value: "Platinum Service",
    },
    { name: "Diamond", price: 20.0, coverage: 2000, value: "Diamond Service" },
  ];

  const handleCheck = (props) => {
    setSelectedService(props.name);
    Store.dispatch(getServiceLevel(props));
  };

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex justify-center items-center w-full bg-[#67809F] p-1">
        <p className="text-[white] font-[600] text-center text-[13px]">
          STEP 1: SELECT LEVEL OF SERVICES
        </p>
      </div>

      <div className="flex  flex-wrap items-center justify-between">
        {services.map((service) => (
          <div key={service.name} className="cursor-pointer">
            <div className="flex flex-col gap-3 items-start">
              <div className="self-stretch relative w-[320px] h-[150px] rounded-[8px] overflow-hidden">
                <img
                  alt="carsimage"
                  className="self-stretch h-full shadow-md"
                  src={ribbonbagsgold}
                />
                <div className="w-full top-0 left-0 h-full absolute bg-[#465568cd]">
                  <div className="py-1 px-4 font-[800] text-[13px] border-2 text-white w-fit border-white-400 rounded uppercase absolute top-[20px] left-[20px]">
                    {service.name}
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col">
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    value={service.name}
                    checked={selectedService === service.name}
                    onChange={() => handleCheck(service)}
                  />
                  <div className="text-[12px] font-[500]">
                    {service.name} Service - ${service.price.toFixed(2)}
                  </div>
                </div>
                <div className="text-[12px] font-[500]">
                  ${service.coverage} Coverage per bag
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceSelection;
