import React, { useEffect, useState } from "react";
import Topbar from "../component/Topbar";
import ReviewInformation from "../component/ReviewInformation";
import { useParams } from "react-router-dom";
import Loader from "../component/Loader/Loading";
import { useDispatch, useSelector } from "react-redux";
import { fetchPassengers } from "../redux/actions/paxnumber";

const Reviewpaypage = () => {
  const [paxdata, setPaxdata] = useState({});
  const { servicekey } = useParams();
  const { isLoading, passengerdata } = useSelector((state) => state.passengers);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch passengers based on service key
    dispatch(fetchPassengers(servicekey));
  }, [servicekey, dispatch]);

  useEffect(() => {
    // Update local state when passenger data changes
    if (passengerdata) {
      setPaxdata(passengerdata);
    }
  }, [passengerdata]);

  return (
    <div className="w-full flex flex-col gap-2 relative">
      {isLoading && <Loader type="spin" text="Uploading Data..." />}
      <Topbar tab={2} />

      <div className="w-[90%] h-full m-auto max-w-[1200px] flex flex-col gap-5 px-[30px] py-3">
        <ReviewInformation
          passengers={paxdata?.passengersinfo}
          serviceDetails={paxdata?.servicelevel}
          pax={paxdata?.paxvalue}
          servicenumber={paxdata?.servicenumber}
        />
      </div>
    </div>
  );
};

export default Reviewpaypage;
