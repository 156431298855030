import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storePassengers } from "../redux/actions/paxnumber";
import PassengerInfo from "./PassengerInfo";

const TripInformation = () => {
  const dispatch = useDispatch();
  const { paxvalue } = useSelector((state) => state.passengers);

  // Initialize passengers based on paxvalue
  const [passengers, setPassengers] = useState(
    Array.from({ length: paxvalue || 1 }, () => ({
      lastName: "",
      firstName: "",
      email: "",
      airline: "",
      confirmation: "",
    }))
  );

  // Sync passengers array with paxvalue
  useEffect(() => {
    setPassengers((prevPassengers) => {
      if (paxvalue > prevPassengers.length) {
        return [
          ...prevPassengers,
          ...Array.from({ length: paxvalue - prevPassengers.length }, () => ({
            lastName: "",
            firstName: "",
            email: "",
            airline: "",
            confirmation: "",
          })),
        ];
      } else if (paxvalue < prevPassengers.length) {
        return prevPassengers.slice(0, paxvalue);
      }
      return prevPassengers;
    });
  }, [paxvalue]);

  // Dispatch passengers to Redux store whenever passengers state changes
  useEffect(() => {
    dispatch(storePassengers(passengers));
  }, [passengers, dispatch]);

  // Handle adding a new passenger
  const handleAddPassenger = () => {
    setPassengers([
      ...passengers,
      {
        lastName: "",
        firstName: "",
        email: "",
        airline: "",
        confirmation: "",
      },
    ]);
  };

  // Handle passenger field updates
  const handlePassengerChange = (index, field, value) => {
    const newPassengers = [...passengers];
    newPassengers[index] = { ...newPassengers[index], [field]: value };
    setPassengers(newPassengers);
  };

  // Handle passenger removal
  const handleRemovePassenger = (index) => {
    if (passengers.length > 1) {
      const newPassengers = passengers.filter((_, i) => i !== index);
      setPassengers(newPassengers);
    }
  };

  return (
    <div className="flex flex-col gap-4 items-center">
      <div className="justify-center items-center w-full bg-[#67809F] p-1">
        <p className="text-[white] font-[600] text-center text-[13px]">
          STEP 3: PROVIDE YOUR TRIP INFORMATION
        </p>
      </div>

      {passengers.map((passenger, index) => (
        <PassengerInfo
          key={index}
          passenger={passenger}
          onPassengerChange={(field, value) =>
            handlePassengerChange(index, field, value)
          }
          onRemovePassenger={() => handleRemovePassenger(index)}
          showAddButton={index === passengers.length - 1} // Show Add button only for the last passenger
          onAddPassenger={handleAddPassenger}
        />
      ))}
    </div>
  );
};

export default TripInformation;
