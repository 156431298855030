import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../server";

export const getNumberofPax = (numberofpassenger) => ({
  type: "Numberofpassenger",
  payload: parseInt(numberofpassenger, 10),
});

export const getServiceLevel = (servicelevel) => ({
  type: "Servicelevel",
  payload: servicelevel,
});

export const storePassengers = (passenegers) => ({
  type: "Storepassengers",
  payload: passenegers,
});

// Action to handle passenger checkout
export const checkoutPassengers = (passengersinfo) => async (dispatch) => {
  try {
    dispatch({ type: "checkoutPaxRequest" });

    const { data } = await axios.post(
      `${server}/baggage/service`,
      passengersinfo,
      { headers: { "Content-Type": "application/json" } }
    );

    dispatch({ type: "checkoutPaxSuccess", payload: data.servicetoken });

    // Explicitly return the result
    return { type: "checkoutPaxSuccess", servicetoken: data.servicetoken };
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred";

    dispatch({ type: "checkoutPaxFail", payload: errorMessage });

    // Explicitly return the failure result
    return { type: "checkoutPaxFail", error: errorMessage };
  }
};

// Fetch pax record
export const fetchPassengers = (servicekey) => async (dispatch) => {
  const token = servicekey;
  try {
    // Dispatch request action
    dispatch({
      type: "fetchPaxRequest",
    });

    // Perform the POST request
    const { data } = await axios.get(`${server}/baggage/service/${token}`, {
      headers: {
        "Content-Type": "application/json", // Explicitly set content type
      },
    });

    // Dispatch success action with response data
    dispatch({
      type: "fetchPaxSuccess",
      payload: data.data, // Adjusted to match the backend response structure
    });
  } catch (error) {
    console.error("Error:", error?.response?.data?.message || error.message);

    // Dispatch failure action with error message
    dispatch({
      type: "fetchPaxFail",
      payload: error?.response?.data?.message || "An error occurred",
    });
  }
};
