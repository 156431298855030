import React, { useState } from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { MdAddCircle } from "react-icons/md";
import { PiMinusCircleFill } from "react-icons/pi";

const ReviewInformation = ({
  passengers,
  serviceDetails,
  pax,
  servicenumber,
}) => {
  // State to track expanded rows
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRow = (index) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    <div className="p-5 w-full rounded-md mx-auto flex flex-col gap-6">
      <div className="w-full flex flex-col gap-3">
        {/* Header */}
        <div className=" justify-center items-center w-full bg-[#67809F] p-2 mb-2">
          <p className="text-[white] font-[600] text-center text-[13px]">
            STEP 1: REVIEW THE INFORMATION
          </p>
        </div>

        {/* Summary of Services */}
        <div className="w-full flex items-start justify-between p-4 border-dashed border-2 border-gray-300">
          <div className="rounded-md mb-5">
            <h2 className="text-lg font-semibold text-gray-600 mb-3">
              SUMMARY OF SERVICES
            </h2>
            <p className="text-sm text-gray-500">
              <span className="font-medium text-gray">Service Number:</span>{" "}
              <b>{servicenumber}</b>
            </p>
            <p className="text-sm text-gray-500">
              <span className="font-medium text-gray">Service Level:</span>{" "}
              <b>{serviceDetails?.value}</b>
            </p>
            <p className="text-sm text-gray-500">
              <span className="font-medium text-gray">Passenger Count:</span>{" "}
              <b>{pax}</b>
            </p>
            <p className="text-sm text-gray-500">
              <span className="font-medium text-gray">Service Cost: </span>
              <b>
                <NumberFormat
                  value={serviceDetails?.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </b>
            </p>
          </div>

          {/* Passenger List */}
          <div className="w-[65%] h-full flex flex-col justify-between rounded-md mb-5">
            <h2 className="text-lg font-semibold text-gray-600 mb-3">
              PASSENGER LIST
            </h2>
            <table className="w-full h-full text-left border-collapse">
              <thead>
                <tr className="border-b border-gray-300">
                  <th className="py-2 text-sm text-gray-600 font-semibold"></th>
                  <th className="py-2 text-sm text-gray-600 font-semibold">
                    Last Name
                  </th>
                  <th className="py-2 text-sm text-gray-600 font-semibold">
                    First Name
                  </th>
                  <th className="py-2 text-sm text-gray-600 font-semibold">
                    Email
                  </th>
                  <th className="py-2 text-sm text-gray-600 font-semibold">
                    PNR #
                  </th>
                </tr>
              </thead>
              <tbody>
                {passengers?.map((passenger, index) => (
                  <>
                    <tr
                      key={index}
                      className="hover:bg-gray-100 border-b border-gray-300"
                    >
                      <td
                        className="py-2 text-sm text-gray-700 cursor-pointer"
                        onClick={() => toggleRow(index)}
                      >
                        {expandedRows.includes(index) ? (
                          <PiMinusCircleFill color="red" size={20} />
                        ) : (
                          <MdAddCircle color="green" size={20} />
                        )}
                      </td>
                      <td className="py-2 text-sm text-gray-700">
                        {passenger.lastName}
                      </td>
                      <td className="py-2 text-sm text-gray-700">
                        {passenger.firstName}
                      </td>
                      <td className="py-2 text-sm text-gray-700">
                        {passenger.email}
                      </td>
                      <td className="py-2 text-sm text-gray-700">
                        {passenger?.confirmation}
                      </td>
                    </tr>
                    {expandedRows.includes(index) && (
                      <tr>
                        <td
                          colSpan="5"
                          className="py-2 text-[12px] text-gray-500"
                        >
                          <p>
                            <b>Originating Airline:</b> {passenger?.airline}
                          </p>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Note */}
        <p className="text-xs text-gray-500">
          * You can edit your information by clicking the "Go Back" button
          without having to reenter your previous information.
        </p>

        {/* Go Back Button */}
        <div>
          <Link
            to="/"
            className="bg-blue-500 hover:bg-blue-600 text-white text-sm py-2 px-4 rounded-sm"
          >
            Go Back
          </Link>
        </div>
      </div>

      <div className=" justify-center items-center w-full bg-[#67809F] p-2 mb-2">
        <p className="text-[white] font-[600] text-center text-[13px]">
          STEP 2: CHOOSE A WAY TO PAY
        </p>
      </div>
    </div>
  );
};

export default ReviewInformation;
