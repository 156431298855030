import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkoutPassengers } from "../redux/actions/paxnumber";

const generateRandomNumber = () => {
  const prefix = "KS"; // Constant prefix
  const randomDigits = Math.floor(100000000 + Math.random() * 900000000); // 9 random digits
  return `${prefix}${randomDigits}`;
};

const Checkout = () => {
  const { paxvalue, servicelevel, passengersinfo, servicekey } = useSelector(
    (state) => state.passengers
  );

  console.log(servicekey);
  const navigate = useNavigate(); // Initialize navigation
  const dispatch = useDispatch();
  const [promoCode, setPromoCode] = useState("");

  const handleCheckout = async () => {
    try {
      // Dispatch the action and wait for completion
      const result = await dispatch(
        checkoutPassengers({
          paxvalue,
          servicelevel,
          passengersinfo,
          servicenumber: generateRandomNumber(),
        })
      );

      if (result.type === "checkoutPaxSuccess") {
        navigate(`/checkoutpage/${result.servicetoken}`);
      } else {
        console.error("Checkout failed:", result.error);
      }
    } catch (error) {
      console.error("An error occurred during checkout:", error);
    }
  };

  return (
    <div className="w-full flex flex-col gap-3 relative">
      <div className=" justify-center items-center w-full bg-[#67809F] p-1">
        <p className="text-[white] font-[600] text-center text-[13px]">
          STEP 4: CHECKOUT
        </p>
      </div>

      <div className="w-full flex items-center justify-end">
        <input
          type="text"
          placeholder="Promo Code"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          className="p-2 outline-none border border-gray-500 text-[12px] w-[180px] min-w-[180px]"
        />

        <button
          className="text-[#45556A] bg-[#EFEFEF] border border-gray-300 px-4 py-2 text-[12px]"
          onClick={() => {
            /* Validate promo code logic here */
          }}
        >
          Validate
        </button>

        <button
          className="bg-[#45556A] text-[white] border-gray-300 px-4 py-2 text-[12px]"
          onClick={handleCheckout}
        >
          Checkout
        </button>
      </div>
    </div>
  );
};

export default Checkout;
