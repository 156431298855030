import { configureStore } from "@reduxjs/toolkit";
import { inputfieldReducer } from "./reducers/paxnumber";

const Store = configureStore({
  reducer: {
    passengers: inputfieldReducer,
  },
});

export default Store;
