// reducers.js
import { createAction, createReducer } from "@reduxjs/toolkit";

const Numberofpassenger = createAction("Numberofpassenger");
const Servicelevel = createAction("Servicelevel");
const Storepassengers = createAction("Storepassengers");
const checkoutPaxSuccess = createAction("checkoutPaxSuccess");
const checkoutPaxRequest = createAction("checkoutPaxRequest");
const checkoutPaxFail = createAction("checkoutPaxFail");
const fetchPaxSuccess = createAction("fetchPaxSuccess");
const fetchPaxRequest = createAction("fetchPaxRequest");
const fetchPaxFail = createAction("fetchPaxFail");

const initialState = {
  paxvalue: 1,
  servicelevel: {
    name: "Diamond",
    price: 20.0,
    coverage: 2000,
    value: "Diamond Service",
  },
  passengersinfo: [],
  servicekey: "",
  isLoading: false,
  passengerdata: {},
  isAuthenticated: false,
};

export const inputfieldReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(Numberofpassenger, (state, action) => {
      state.paxvalue = action.payload;
    })

    .addCase(Servicelevel, (state, action) => {
      state.servicelevel = action.payload;
    })

    .addCase(Storepassengers, (state, action) => {
      state.passengersinfo = action.payload;
    })

    .addCase(checkoutPaxRequest, (state, action) => {
      state.isLoading = true;
    })

    .addCase(checkoutPaxSuccess, (state, action) => {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.servicekey = action.payload;
    })

    .addCase(checkoutPaxFail, (state, action) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(fetchPaxRequest, (state, action) => {
      state.isLoading = true;
    })

    .addCase(fetchPaxSuccess, (state, action) => {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.passengerdata = action.payload;
    })

    .addCase(fetchPaxFail, (state, action) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = action.payload;
    });
});
