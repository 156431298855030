import React from "react";

const Topbar = ({ tab }) => {
  return (
    <div className="w-full bg-[#67809F] px-[30px] py-3 flex justify-center items-center">
      <span className="w-[90%] text-[25px] font-[500] text-[white]">
        {tab !== 2
          ? "KIRASURF PURCHASE BAGGAGE SERVICE"
          : "CONFIRM YOUR DELAYED BAGGAGE SERVICE PURCHASE - CHECKOUT"}
      </span>
    </div>
  );
};

export default Topbar;
